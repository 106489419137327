import React from 'react';

function Landing() {

  return (
    <section className="landing"> 
      <div className="home">
        <h1>dr. Kovács Ildikó</h1>
        <h2>
          egyéni ügyvéd
        </h2>
      </div>
      <a href="#bemutatkozas" className="scroll-down">&nbsp;</a>
    </section>
  );
}

export default Landing;
