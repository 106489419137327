import React from 'react';
// AIzaSyAfOaqkxeXEWBLxtYUbaCleVlgWdsaouI0
function Contact() {

  const emailAddress = "ildiko.kovacs@avocat.hu";
  const h = function(e) {
    e.preventDefault();
    window.location.href = 'mailto:' + emailAddress;
  };

  let map;
  let map2;
  const google = window.google;
  async function initMaps() {
    const position = { lat: 47.520079671457104, lng: 19.06701919703855 };
    const position2 = { lat: 47.489208467105335, lng: 19.253452769214483 };
    //@ts-ignore
    const { Map } = await google.maps.importLibrary("maps");
    const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");

    // The map, centered at Uluru
    map = new Map(document.getElementById("map1"), {
      zoom: 17,
      center: position,
      mapId: "Iroda",
    });

    // The marker, positioned at Uluru
    const marker = new AdvancedMarkerElement({
      map: map,
      position: position,
      title: "Iroda",
    });

    map2 = new Map(document.getElementById("map2"), {
      zoom: 16,
      center: position2,
      mapId: "Iroda",
    });
    const marker2 = new AdvancedMarkerElement({
      map: map2,
      position: position2,
      title: "Aliroda",
    });
  }

  initMaps();

  return (
    <section className="contact" id="elerhetosegeim">
      <h1>Elérhetőségeim</h1>
      <h2>dr. Kovács Ildikó</h2>
      <h2>Telefon: +36 1 317 2831</h2>
      <h2>Mobil: +36 30 9660 331</h2>
      <h2>E-mail: <a href="#" onClick={h}>{emailAddress}</a></h2>

      <div className="content">
        <h2><b>Iroda</b></h2>
        <h3>1134 Budapest, Lehel utca 12.</h3>
        <div className="map" id="map1"></div>
      </div>
      <div className="content">
        <h2><b>Aliroda</b></h2>
        <h3>1172 Budapest, V. utca 39.</h3>
        <div className="map" id="map2"></div>
      </div>
      <br className="clear" />
      <div className="copy">
        dr. Kovács Ildikó
        <br />
        © 2023
      </div>
    </section>
  );
}

export default Contact;
