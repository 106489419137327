import React from 'react';
import drKovacsIldiko from '../assets/dr-kovacs-ildiko.png';

function Introduction() {
  return (
    <section className="introduction" id="bemutatkozas">
      <div className="details">
        
        <h3>Bemutatkozás</h3>
        <div className="image">
          <img src={drKovacsIldiko} alt="Dr Kovács Ildikó Erzsébet" />
        </div>
        <div className="text">
          <p>dr. Kovács Ildikó egyéni ügyvéd vagyok.</p>
          <p>Több évtizede Budapesten praktizálok, főként ingatlan és cég ügyekkel foglalkozom, ezen belül elsősorban ingatlanok tulajdonjogának átruházásával, megterhelésével kapcsolatos okiratok elkészítésével, ellenjegyzésével, valamint a földhivatal előtti képviselettel.</p>
          <p>A Budapesti Ügyvédi Kamara tagja vagyok, kamarai azonosító számom: 36063746.</p>
        </div>
        <br className="clear" />
        <hr />

        <h3>Profil</h3>

        <div className="articles">
          <div className='item'>
            <h4>INGATLANJOG</h4>
            <ul>
              <li>ingatlan adásvételi szerződések készítése</li>
              <li>a földtörvény hatálya alá tartozó átruházási szerződések készítése, valamint az ezzel kapcsolatos engedélyeztetési eljárás lefolytatása</li>
              <li>közös tulajdon megszüntetésével kapcsolatos szerződések elkészítése, illetve véleményezése</li>
              <li>ajándékozási szerződések készítése</li>
              <li>használati megosztási szerződések készítése</li>
              <li>társasházi alapító okiratok készítése</li>
              <li>életjáradéki és tartási szerződések készítése</li>
              <li>teljes körű földhivatali ügyintézés</li>
              <li>tulajdoni lap, valamint ingatlan-nyilvántartási térképmásolat beszerzés</li>
              <li>hitel- és jelzálog szerződések készítése</li>
              <li>bérleti és használati szerződések</li>
              <li>ingatlanügyletre vonatkozó meghatalmazások készítése</li>
              <li>ingatlanokkal kapcsolatos jogügyletekben jogi tanácsadás és képviselet</li>
              <li>szerződések jogi véleményezése</li>
            </ul>
          </div>
          
          <div className='item'>
            <h4>TÁRSASÁGI JOG</h4>
            <ul>
              <li>gazdasági társaságok (főként Kft., Bt.) alapítása, a teljes körű cégbejegyzés lefolytatása</li>
              <li>társasági szerződések és módosítások készítése, a változásbejegyzési eljárás lefolytatása</li>
              <li>aláírás minták</li>
              <li>taggyűlési jegyzőkönyvek, valamint taggyűlési határozatok szerkesztése</li>
              <li>üzletrész adásvételi szerződések készítése</li>
              <li>tagjegyzék</li>
            </ul>
          </div>
          
          <div className='item'>
            <h4>ÖRÖKLÉSI JOG</h4>
            <ul>
              <li>végrendelet készítése</li>
              <li>öröklési szerződések készítése</li>
            </ul>
          </div>
          
          <div className='item'>
            <h4>EGYÉB</h4>
            <ul>
              <li>alapítványok alapító okirata, valamint az egyéb kapcsolódó iratok elkészítése</li>
              <li>alapítványok nyilvántartásba vételével kapcsolatos eljárás lefolytatása</li>
            </ul>
          </div>

          <br className="clear" />
        </div>
      </div>

      <br className="clear" />
    </section>
  );
}

export default Introduction;

/*



        */